/* DetailGTG.css */

.detail-GTG-container {
    /* padding: 20px; */
    display: flex;
    max-width: 1500px;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
}

.gtg-image-banner {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: center;
    gap: 1%;
    width: 100%;
    max-width: 1500px;
    /* Max width of the grid */
    margin-bottom: 2%;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    padding: 0 5%;
    /* Added padding to ensure the grid fits within the container */
}

.phone-ss {
    flex: 1 1 auto;
    max-width: 239px;
    /* Maximum width by default */
    height: auto;
    border: 5px solid black;
    border-radius: 20px;
    object-fit: contain;
}

@media (max-width: 1485px) {
    .gtg-image-banner {
        grid-template-columns: repeat(3, 25%);
        grid-template-rows: auto auto;
        justify-content: center;
        column-gap: 3rem;
    }

    .phone-ss {
        width: 100%;
        /* Fixed width when the screen is less than 1485px */
    }
}

.detail-GTG-container h1 {
    font-size: 2rem;
    color: #333;
    /* Dark grey text color */
}

.detail-GTG-container p {
    font-size: 1rem;
    color: #666;
    /* Medium grey text color */
}

.text-section {
    /* padding-top: 50px; */
    display: flex;
    /* Use Flexbox for horizontal alignment */
    justify-content: space-between;
    /* Spread the children horizontally */
    width: 100%;
    /* Ensure it takes the full width of the parent */
}

.text {
    flex: 7;
    /* 70% width, based on a ratio of 7/10 */
    padding: 1%;
    /* Optional padding */
}

.list {
    flex: 3;
    /* 30% width, based on a ratio of 3/10 */
    padding: 2%;
    /* Optional padding */
}

.detail-gtg-list {
    padding-top: 10px;
    list-style-type: disc;
    /* Default bullet points */
    padding-left: 20px;
    /* Indentation for bullet points */
    text-align: left;
}

.detail-item {
    margin-bottom: 10px;
    /* Space between list items */
}