/* DetailLAII.css */

.detail-laii-container {
    /* padding: 20px; */
    display: flex;
    max-width: 1500px;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
}

.laii-image-banner {
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-height: 250px;
    margin-bottom: 2%;
}

.laii-image-banner img {
    max-width: 33%;
    height: auto;
    object-fit: contain;
}

.detail-laii-container h1 {
    font-size: 2rem;
    color: #333;
    /* Dark grey text color */
}

.detail-laii-container p {
    font-size: 1rem;
    color: #666;
    /* Medium grey text color */
}

/* DetailLAII.css */

.text-section {
    display: flex;
    /* Use Flexbox for horizontal alignment */
    justify-content: space-between;
    /* Spread the children horizontally */
    width: 100%;
    /* Ensure it takes the full width of the parent */
}

.text {
    flex: 7;
    /* 70% width, based on a ratio of 7/10 */
    padding: 1%;
    /* Optional padding */
}

.list {
    flex: 3;
    /* 30% width, based on a ratio of 3/10 */
    padding: 2%;
    /* Optional padding */
}

.detail-list {
    padding-top: 10px;
    padding-right: 1%;
    /* margin-top: 40px; */
    list-style-type: disc;
    /* Default bullet points */
    /* padding-left: 20px; */
    /* Indentation for bullet points */
    text-align: left;
}

.detail-item {
    margin-bottom: 10px;
    /* Space between list items */
}

/* Stacking layout for smaller screens */
@media (max-width: 600px) {
    .text-section {
        flex-direction: column;
        /* Stack vertically */
    }

    .text,
    .list {
        flex: 1 1 100%;
        /* Take full width */
        padding: 10px 0;
    }
}