.App {
  text-align: center;
  /* background-color: green; */
  display: flex;
  flex-direction: column;
  /* width: 100vw; */
  max-width: 100%;
  align-items: stretch;
  /* Ensure children stretch to the container width */
  /* width: 600px; */
}

.intro-container {
  padding-bottom: 45px;
  max-width: 85%;
  align-items: center;
  margin: 0 auto;
}

.resume-title {
  padding: 1%;
  font-family: 'Source Code Pro';
  font-size: 4rem;
  /* font-weight: bold; */
}

.projects-container {
  padding-top: 45px;
  background-color: #f3f2ff;
  padding-bottom: 100px;
  width: 100%;
  margin: 0 auto;
}

.resume-container {
  padding-top: 45px;
  padding-bottom: 100px;
}