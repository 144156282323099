/* Resume.css */
body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
}

.resume {
    max-width: 1000px;
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.resume-header {
    text-align: center;
    margin-bottom: 20px;
}

.resume-section h2 {
    font-size: 1.8em;
    margin-bottom: 10px;
    border-bottom: 2px solid #333;
    padding-bottom: 5px;
    text-align: center;
    /* Center the titles */
}

.resume-section p,
.resume-section ul {
    text-align: left;
    /* Ensure content is left-aligned */
    margin: 5px 0;
    font-size: 1.1em;
}

.experience p {
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
    margin: 0;
}

.date {
    float: right;
    text-align: right;
    margin-left: auto;
}

/* Handle list styling */
.resume-section ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-left: 0;
}

.resume-section ul li {
    margin-left: 20px;
}

/* For Project Section */
.project-section li {
    padding-top: 15px;
}

/* To get the projects to have a nice space between themselves */
.resume-project {
    padding-bottom: 1%;
}