.grid-container {
    margin: auto;
    width: 100%;
}

.grid-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* 2 columns */
    gap: 5%;
    padding: 1% 0;
    max-width: 90%;
    margin: 0 auto;
}

.grid-item-wrapper {
    position: relative;
    text-align: center;
}

.grid-item {
    border: 1px solid #ccc;
    background-color: #FCFCFC;
    text-align: center;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease, border-width 0.3s ease;
    cursor: pointer;
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.grid-item:hover {
    border-color: #007BFF;
    border-width: 5px;
}

.grid-item.selected {
    border-color: #007BFF;
    border-width: 5px;
}

.grid-image {
    width: 100%;
    max-height: 200px;
    max-width: 400px;
    height: auto;
    object-fit: contain;
    padding-top: 2%;
}

.grid-caption {
    font-size: 2rem;
    font-weight: bolder;
    font-family: Arial, Helvetica, sans-serif;
    color: #333;
    text-align: center;
    margin-top: auto;
}

.card-body {
    box-shadow: 0 4px 8px rgba(0, 0, 50, .5);
    height: auto;
    width: 100%;
    margin: 20px auto;
}

.collapse.show .card-body {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 600px) {

    /* Scale down the grid and its contents on smaller screens */
    .grid-row {
        grid-template-columns: repeat(2, minmax(120px, 1fr));
        gap: 10px;
    }

    .grid-item {
        padding: 10px;
    }

    .grid-image {
        max-height: 100px;
        max-width: 200px;
    }

    .grid-caption {
        font-size: 1.5rem;
    }

    .card-body {
        margin: 10px auto;
        padding: 10px;
    }
}