/* Projects.css */

.Projects {
    text-align: center;
    align-items: center;
}

.Projects-Container {
    background-color: #f3f2ff;
    max-width: 80%;
    margin: 0 auto;
}

.projects-title {
    padding: 1%;
    font-family: 'Source Code Pro';
    font-size: 4rem;
    /* font-weight: bold; */
}