/* Footer.css */
.footer {
    text-align: center;
    padding: 10px;
    background-color: #f3f2ff;
    border-top: 1px solid #ccc;
    position: fixed;
    width: 100%;
    bottom: 0;
}

.footer p {
    margin: 5px 0;
    font-size: 1em;
}

.footer a {
    color: #333;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}