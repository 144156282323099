.intro-component-container {
    display: flex;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    /* Allows items to wrap on smaller screens */
}

.name-intro-cont {
    width: 60%;
    margin-top: 2%;
    padding: 4% 0;
    margin-left: 8%;
    border-radius: 10%;
    background-color: #f3f2ff;
}

.total-container {
    width: 100%;
}

.intro-text-cont {
    padding-top: 3%;
    margin: 0 4%;
}

.intro-text-cont .intro-text {
    font-family: 'Georgia', serif;
    font-size: 1.2rem;
}

.picture-container {
    width: 35%;
    background-color: #f3f2ff;
    border-radius: 15%;
    margin-top: 2%;
    display: flex;
    justify-content: center;
    /* Centers the image horizontally */
    align-items: center;
    /* Centers the image vertically */
}

.headshot {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
    border-radius: 40%;
    transform: scaleX(-1);
}

.name-container h1 {
    font-family: 'Source Code Pro', monospace;
    font-size: 3.5rem;
}

.website-banner-image {
    padding-top: 6%;
    margin: 0 auto;
    height: auto;
    width: 85%;
    max-width: 100vw;
    object-fit: contain;
    align-items: center;
    align-content: center;
}

.bannerImg {
    width: 100%;
    height: auto;
    margin: 0 auto;
    max-height: 250px;
    border-radius: 20%;
    border: 4mm ridge rgba(0, 0, 100, .25);
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .name-intro-cont {
        width: 70%;
        margin-left: 5%;
    }

    .picture-container {
        width: 30%;
    }

    .name-container h1 {
        font-size: 3rem;
    }

    .intro-text-cont .intro-text {
        font-size: 1.1rem;
    }
}

@media (max-width: 900px) {
    .name-intro-cont {
        width: 80%;
        margin-left: 0;
        margin-top: 4%;
    }

    .picture-container {
        width: 40%;
    }

    .name-container h1 {
        font-size: 2.5rem;
    }

    .intro-text-cont .intro-text {
        font-size: 1rem;
    }
}

@media (max-width: 600px) {
    .intro-component-container {
        flex-direction: column;
        /* Stacks items vertically on small screens */
        align-items: center;
    }

    .name-intro-cont {
        width: 90%;
        margin-left: 0;
    }

    .picture-container {
        width: 70%;
    }

    .name-container h1 {
        font-size: 2rem;
    }

    .intro-text-cont .intro-text {
        font-size: 0.9rem;
    }

    .bannerImg {
        max-height: 200px;
    }
}